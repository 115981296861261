import { Controller } from "@hotwired/stimulus";
import tooltips from "../../tools/tooltips";
import pasteBufferCopy from "../../tools/paste_buffer_copy";

export default class extends Controller {
  static values = {
    text: String
  }
  copy (e) {
    e.preventDefault()
    const shareUrl = this.textValue
    if (shareUrl) {
      pasteBufferCopy(shareUrl)
      this._notifyCopied()
    }
  }
  _notifyCopied () {
    tooltips.pop(this.element, 'Copied to clipboard')
  }
}
