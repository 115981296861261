import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ['currencySelector', 'propertyValueLabels']
  }

  connect () {
    this.swapCurrencyRangeText();
  }

  swapCurrencyRangeText(e){
    const selectedCurrencyOption =
      this.currencySelectorTarget[this.currencySelectorTarget.selectedIndex];
    const currencySymbol = selectedCurrencyOption.dataset.currencySymbol;
    this.propertyValueLabelsTargets.forEach((propertyValueLabel) => {
      let currencyRangeTexts = JSON.parse(propertyValueLabel.dataset.currencyRangeText);
      propertyValueLabel.innerHTML = currencyRangeTexts[currencySymbol];
    });
  }
}
