var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { attrs: { action: _vm.formatConfirmUrl(), method: "post" } },
    [
      _c("input", {
        attrs: { type: "hidden", name: "authenticity_token" },
        domProps: { value: _vm.csrf }
      }),
      _vm._l(_vm.sortedDeposits, function(deposit) {
        return _c("Deposit", {
          key: deposit.identifer,
          attrs: {
            deposit: deposit,
            requireConfirmationIds: _vm.requireConfirmationIds,
            ownerCanNyop: _vm.ownerCanNyop
          },
          on: { removeDeposit: _vm.depositRemoved }
        })
      }),
      _c(
        "div",
        {
          staticClass: "agree-to-terms-container",
          staticStyle: { "margin-top": ".5rem" }
        },
        [
          _c(
            "div",
            {
              staticClass: "input check_boxes",
              staticStyle: { "margin-bottom": "0" }
            },
            [
              _c("span", { staticClass: "checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checked,
                      expression: "checked"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    name: "agree_to_liability_for_reservation",
                    id: "agree_to_liability_for_reservation",
                    value: "1"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.checked)
                      ? _vm._i(_vm.checked, "1") > -1
                      : _vm.checked
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "1",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checked = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checked = $$c
                      }
                    }
                  }
                }),
                _vm._m(0)
              ])
            ]
          ),
          _c("input", {
            staticClass: "button full",
            attrs: {
              type: "submit",
              value: "Confirm Weeks",
              "data-disable-with": "Processing...",
              disabled: _vm.confirmButtonDisabled
            }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { attrs: { for: "agree_to_liability_for_reservation" } },
      [
        _c("p", { staticClass: "small" }, [
          _vm._v("I agree to"),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.thirdhome.com/terms-and-conditions/",
                target: "_blank"
              }
            },
            [_vm._v(" ThirdHome's Terms and Conditions")]
          ),
          _vm._v(
            ", including that: (1) I am not permitted to cancel reservations (2) When I add a week, it is immediately eligible to be reserved and additional confirmation is not required, and (3) All costs incurred by the Guest if I cancel, including Exchange Fee, are my responsibility."
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }