import loader from './mapbox';
import EventEmitter from '../tools/event-emitter';
import getPropertyTooltips from './property-tooltips';

class ClusterMap {
  constructor (options) {
    this.started = false;
    this.element = null;
    this.data = null;
    this.viewport = null;
    this.center = null;
    this.zoom = null;
    this.mapboxgl = null;
    this.map = null;
    this.events = new EventEmitter();
    this.hasUser = true;
    this._assignOptions(options);
  }
  start (options) {
    this._assignOptions(options);
    if (this.starter) { return this.starter };
    this.starter = new Promise((resolve, reject) => {
      const initParams = {
        container: this.element,
        style: 'mapbox://styles/thirdhome/ck864eblw0s581inps0bvfwz4',
        maxZoom: this._maxZoom
      }
      Object.assign(initParams, this._buildViewPort()),
      loader.then((mapboxgl) => {
        if (!mapboxgl.supported()) {
          const resultsPaneMapContainer = document.getElementById("results-pane-map")
          resultsPaneMapContainer.style.position = "relative";
          const overlay = document.createElement('div');
              overlay.style.position = 'absolute';
              overlay.style.top = '0';
              overlay.style.left = '0';
              overlay.style.width = '100%';
              overlay.style.height = '100%';
              overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.7)'; // Semi-transparent background
              overlay.style.color = 'white';
              overlay.style.display = 'flex';
              overlay.style.alignItems = 'center';
              overlay.style.justifyContent = 'center';
              overlay.style.fontSize = '18px';
              overlay.style.borderRadius = '5px';
              overlay.style.textAlign = 'center';
              overlay.innerText = "Map failed to load as your browser isn't supported.\n Please update to the latest version of Chrome, Firefox, or Safari.";          
          resultsPaneMapContainer.appendChild(overlay);
        } else {
          this.mapboxgl = mapboxgl;
          const map = new mapboxgl.Map(initParams);
          this.map = map;
          map.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'top-left');
          map.on('load', () => {
            this.started = true;
            this._mapLoaded();
            resolve(map);
          });          
        };
      });
    });
    return this.starter;
  }
  remove () {
    this.map && this.map.remove();
  }
  resize () {
    this.map && this.map.resize();
  }
  _buildViewPort () {
    let swLng, swLat, neLng, neLat;

    if (this.viewport) {
      [swLng, swLat, neLng, neLat] = this.viewport.map(parseFloat);
      // handles case where viewport crosses Intl Date Line
      if (neLng < swLng) {
        neLng = neLng + 360;
      }
      return {
        bounds: [[swLng, swLat], [neLng, neLat]],
        fitBoundsOptions: {
          padding: this._boundPadding
        }
      }
    } else {
      return {
        center: this.center,
        zoom: this.zoom
      }
    }
  }
  on (eventName, listener) {
    this.events.on(eventName, listener);
  }
  _emit (eventName, ...data) {
    this.events.emit(eventName, this, ...data);
  }
  _assignOptions (options) {
    ['element', 'data', 'viewport', 'zoom', 'center', 'hasUser'].forEach((field) => {
      if (options.hasOwnProperty(field)) {
        this[field] = options[field];
      };
    });
  }
  updateView(center, zoom) {
    this.center = center;
    this.zoom = zoom;
    if (!this.map) return;

    this.map.flyTo({center: center, zoom: zoom});
  }
  fitPoints () {
    const features = this.data.features;
    const currentBounds = this.map.getBounds();
    if (features.every((feature) => currentBounds.contains(feature.geometry.coordinates))) return;

    const fitBounds = features.reduce(function(bounds, feature) {
      return bounds.extend(feature.geometry.coordinates)
    }, new this.mapboxgl.LngLatBounds());

    this.map.fitBounds(fitBounds, {
      maxZoom: 10,
      padding: this._boundPadding
    })
  }
  set data(newData) {
    if (this._data == newData) { return }
    this._data = newData;
    if (this.map) {
      this.map.getSource('points').setData(newData);
    }
  }
  get data () {
    return this._data;
  }
  updateViewport (newViewport) {
    this.viewport = newViewport;
    if (newViewport && this.started) {
      let viewportSettings = this._buildViewPort();
      this.map.fitBounds(viewportSettings.bounds, {
        padding: viewportSettings.fitBoundsOptions.padding
      });
    }
  }
  get location () {
    const coords = this.map.getCenter();
    const zoom = this.map.getZoom();
    return {lng: coords.lng, lat: coords.lat, zoom: zoom};
  }
  updateData(data) {
    this.data = data;
    if (this.map) {
      this.map.getSource('points').setData(data);
    }
  }
  _emitMoved () {
    this._emit('move', this.location);
  }
  async getFeatureTooltips (features) {
    return getPropertyTooltips(features)
      .then((html) => html)
      .catch((e) => {
          const errorMessage = 
            (e.tooltipResponse == 404) ? 'This property is no longer listed for reservations' : 'There was an error loading this tooltip'
          console.log(errorMessage, e);
          return `
            <div class="map-tooltip">
              <div class="card-content">
                 ${errorMessage}
              </div>
            </div>
          `;
      });
  }
  async _clickPoint (e) {
    let coordinates = e.features[0].geometry.coordinates.slice();
    const features = e.features;

    const propertyHTML = await this.getFeatureTooltips(features);
    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    new this.mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(propertyHTML)
      .addTo(this.map);
  }
  async _clickFuzzyPoint(e) {
    const zoom = this.map.getZoom()
    let coordinates = e.features[0].geometry.coordinates.slice();
    const features = e.features;

    const propertyHTML = await this.getFeatureTooltips(features);

    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    new this.mapboxgl.Popup({
        offset: zoom * 2
      })
      .setLngLat(coordinates)
      .setHTML(propertyHTML)
      .addTo(this.map);
  }
  _clickCluster (e) {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: ['clusters']
    });
    const clusterId = features[0].properties.cluster_id;
    const pointCount = features[0].properties.point_count;

    if (this.map.getMaxZoom() == this.map.getZoom()) {
      this.map.getSource('points').getClusterLeaves(clusterId, pointCount, 0, async (error, features) => {
        if (error) { return; }
        const propertyHTML = await this.getFeatureTooltips(features)

        new this.mapboxgl.Popup({
          className: 'paginated-tooltip'
        })
        .setLngLat(features[0].geometry.coordinates)
        .setHTML(propertyHTML)
        .addTo(this.map);
      });
    }

    this.map.getSource('points').getClusterLeaves(clusterId, 1000, 0, (error, features) => {
      if (error) return;

      // Example drawn from https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
      const fitBounds = features.reduce(function(bounds, feature) {
        return bounds.extend(feature.geometry.coordinates)
      }, new this.mapboxgl.LngLatBounds());

      this.map.fitBounds(fitBounds, {
        maxZoom: this._maxZoom,
        padding: this._boundPadding
      })
    });
  }
  get _boundPadding () {
    return (this.element.offsetHeight * .25) / 2;
  }

  get _maxZoom () {
    return this.hasUser ? 15 : 10;
  }

  get _clusterMaxZoom () {
    return this.hasUser ? 16 : 12;
  }

  get _unclusteredPointCircleColor () {
    return this.hasUser ? ["case", [">",  ["get", "available_weeks_count"], 0], "#148BE0",[">",  ["get", "outside_availability_weeks_count"], 0], "#148BE0", '#95a5a6']
                        : '#148BE0'
  }
  _mapLoaded () {
    const map = this.map;
    const controller = this;
    
    map.addSource('points', {
      type: 'geojson',
      data: this.data,
      cluster: true,
      clusterMaxZoom: this._clusterMaxZoom, // Max zoom to cluster points on
      clusterRadius: 30 // Radius of each cluster when clustering points (defaults to 50)
    });

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'points',
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#5D9FCC',
          10,
          '#4F928D',
          51,
          '#E6AD02',
          101,
          '#CE9857',
          201,
          '#EB6C22'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          12,
          10,
          16,
          51,
          20,
          101,
          24,
          201,
          28
        ],
        'circle-stroke-width': [
          'step',
          ['get', 'point_count'],
          5,
          10,
          7,
          51,
          9,
          101,
          11,
          201,
          15
        ],

        'circle-stroke-color': [
          'step',
          ['get', 'point_count'],
          'hsla(204, 33%, 64%,.25)',
          10,
          'hsla(176, 30%, 44%,.25)',
          51,
          'hsla(45, 98%, 45%,.25)',
          101,
          'hsla(33, 65%, 63%,.25)',
          201,
          'hsla(22, 83%, 53%,.25)'
        ]
      }
    });

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'points',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      },
      paint: {
        'text-color': '#fff'
      }
    });

    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'points',
      filter: ['all', ['!', ['has', 'point_count']], ['==', 'exact', ['get', 'map_mode']]],
      paint: {
        'circle-color': this._unclusteredPointCircleColor,
        'circle-radius': 6.5,
        'circle-stroke-width': 2.5,
        'circle-stroke-color': '#fff'
      }
    });

    map.addLayer({
      id: 'unclustered-point-fuzzy',
      type: 'circle',
      source: 'points',
      filter: ['all', ['!', ['has', 'point_count']], ['==', 'fuzzy', ['get', 'map_mode']]],
      paint: {
        'circle-color': this._unclusteredPointCircleColor,
        'circle-radius': [
          "interpolate", ["linear"], ["zoom"],
          10.5, 6.5,
          13, 50,
          14, 100,
          14.5, 200,
        ],
        'circle-stroke-width': 2.5,
        'circle-opacity': [
          "interpolate", ["linear"], ["zoom"],
          10.5, 1,
          11, .15
        ],
        'circle-stroke-color': '#fff'
      }
    });

    // inspect a cluster on click
    map.on('click', 'clusters', (e) => this._clickCluster(e) );
    map.on('click', 'unclustered-point', (e) => this._clickPoint(e) );
    map.on('click', 'unclustered-point-fuzzy', (e) => this._clickFuzzyPoint(e));

    map.on('mouseenter', 'clusters', function() {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', function() {
      map.getCanvas().style.cursor = '';
    });
    map.on('mouseenter', 'unclustered-point', function() {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'unclustered-point', function() {
      map.getCanvas().style.cursor = '';
    });

    map.on('moveend', function () {
      controller._emitMoved();
    });

    map.on('zoomend', function () {
      controller._emitMoved();
    });
  }
}

export default ClusterMap;
export { ClusterMap };
