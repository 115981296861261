import init from '../init';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

function setScrolled() {
  $(document.body).toggleClass('scrolled-body', $(window).scrollTop() > 50);
}

init.onLoad(setScrolled);
$(document).on('scroll', setScrolled);
