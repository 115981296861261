var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "property-photos edit-property-form" },
    [
      _c("PrimaryPhoto", { attrs: { photo: _vm.primaryPhoto } }),
      _c(
        "div",
        { attrs: { id: "photos-for-editing" } },
        [
          _c("h4", [_vm._v("All Photos")]),
          _vm.photos.length > 1
            ? _c("div", { staticClass: "instructions" }, [
                _c(
                  "p",
                  {
                    staticClass: "small",
                    staticStyle: { "margin-top": "-1rem" }
                  },
                  [
                    _vm._v(
                      "Put your best photos first by dragging and  dropping. The photo in the first position is your property's primary photo."
                    )
                  ]
                )
              ])
            : _vm._e(),
          _c(
            "draggable",
            {
              staticClass: "thumbnails grid edge",
              on: { end: _vm.savePositions },
              model: {
                value: _vm.photos,
                callback: function($$v) {
                  _vm.photos = $$v
                },
                expression: "photos"
              }
            },
            _vm._l(_vm.photos, function(photo, i) {
              return _c("PhotoThumbnail", {
                key: photo.id,
                attrs: {
                  photo: photo,
                  showEditModal: function() {
                    return _vm.showEditModal(i)
                  }
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("AddPhoto", {
        attrs: { photoUploadUrl: _vm.photoUploadUrl },
        on: { "update:photos": _vm.setPhotos }
      }),
      _c("br"),
      _c("button", { staticClass: "button", on: { click: _vm.nextStep } }, [
        _vm._v("Next Step")
      ]),
      _vm._v("    "),
      _c(
        "button",
        { staticClass: "button", on: { click: _vm.finishEditing } },
        [_vm._v("Finish Editing")]
      ),
      _c(
        "div",
        { ref: "modal", staticClass: "edit-photo-img-description-modal modal" },
        [
          _vm.modalPhoto
            ? _c("div", { staticClass: "card property-card" }, [
                _c("div", { staticClass: "card-image" }, [
                  _c(
                    "div",
                    {
                      staticClass: "slick-arrow slick-prev",
                      class: _vm.leftArrowClass,
                      on: { click: _vm.navigateLeft }
                    },
                    [_c("span")]
                  ),
                  _c("img", {
                    attrs: {
                      src: _vm.modalPhoto.small,
                      width: "100%",
                      height: "100%",
                      alt: "image_description",
                      title: "image_description"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "slick-arrow slick-next",
                      class: _vm.rightArrowClass,
                      on: { click: _vm.navigateRight }
                    },
                    [_c("span")]
                  )
                ]),
                _c("div", { staticClass: "card-content" }, [
                  _c("h4", { staticClass: "text-left" }, [
                    _vm._v("Image Description"),
                    _c("div", { staticClass: "input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.imageDescriptionText,
                            expression: "imageDescriptionText"
                          }
                        ],
                        staticClass: "image-description",
                        attrs: {
                          placeholder:
                            "ex: Master bedroom balcony with forest views",
                          maxlength: "70"
                        },
                        domProps: { value: _vm.imageDescriptionText },
                        on: {
                          blur: _vm.setCurrentImageDescription,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.imageDescriptionText = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "text-right character-count",
                        class: _vm.countClass
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.descriptionCharCount) +
                            "/60 Characters"
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "card-controls" }, [
                  _c("div", { staticClass: "grid edge" }, [
                    _c("div", { staticClass: "cell fill" }, [
                      _vm.modalPhotoIndex > 0
                        ? _c(
                            "a",
                            {
                              staticClass: "make-featured",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.makePrimary($event)
                                }
                              }
                            },
                            [_vm._v("Make Featured Image")]
                          )
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "delete-image",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.askDeleteImage($event)
                            }
                          }
                        },
                        [_vm._v("Delete Image")]
                      )
                    ]),
                    _vm._m(0)
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell fit" }, [
      _c("a", { staticClass: "text-close", attrs: { href: "#" } }, [
        _vm._v("Close")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }