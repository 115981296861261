<template lang='pug'>
  form(:action='formatConfirmUrl()', method='post')
    input(type='hidden' name="authenticity_token" :value="csrf")
    Deposit(v-for='deposit in sortedDeposits'
            :key='deposit.identifer'
            :deposit='deposit'
            :requireConfirmationIds='requireConfirmationIds',
            :ownerCanNyop='ownerCanNyop',
            @removeDeposit='depositRemoved')
    .agree-to-terms-container(style="margin-top:.5rem")
      .input.check_boxes(style="margin-bottom:0")
        span.checkbox
          input(type='checkbox'
                name='agree_to_liability_for_reservation'
                id='agree_to_liability_for_reservation'
                v-model='checked'
                value='1')
          label(for='agree_to_liability_for_reservation')
            p.small
              | I agree to
              a(href="https://www.thirdhome.com/terms-and-conditions/" target="_blank")  ThirdHome's Terms and Conditions
              |, including that: (1) I am not permitted to cancel reservations (2) When I add a week, it is immediately eligible to be reserved and additional confirmation is not required, and (3) All costs incurred by the Guest if I cancel, including Exchange Fee, are my responsibility.

      input(type='submit'
            class='button full'
            value='Confirm Weeks'
            data-disable-with='Processing...'
            :disabled='confirmButtonDisabled')
</template>

<script>
import Deposit from './Deposit.vue'
import Token from '../../tools/csrf_token.js'

export default {
  components: { Deposit },
  props: {
    selectedDeposits: { type: Array, required: true },
    confirmUrl: { type: String, required: true },
    requireConfirmationIds: { type: Boolean, required: true },
    shouldTrackPrompt: { type: Boolean, required: true },
    redirectPath: { type: String, required: false },
    ownerCanNyop: { type: Boolean, required: false }
  },
  data () {
    return {
      checked: false,
      csrf: Token()
    }
  },
  computed: {
    needsResortConfirmationIds () {
      if(this.requireConfirmationIds) {
        return !this.selectedDeposits.every(d => d.resort_confirmation_id)
      } else {
        return false
      }
    },
    confirmButtonDisabled () {
      return !this.checked || this.needsResortConfirmationIds
    },
    sortedDeposits () {
      function compare(a, b) {
        const aStart = dayjs(a.starts_on);
        const bStart = dayjs(b.starts_on);

        if (aStart.isBefore(bStart))
          return -1;
        if (bStart.isBefore(aStart))
          return 1;
        return 0;
      }

      return this.selectedDeposits.sort(compare);
    }
  },
  methods: {
    depositRemoved (identifier) {
      this.$emit('removeDeposit', identifier);
    },
    formatConfirmUrl () {
      const queryString = this.buildQueryString()
      if(queryString == "") { return this.confirmUrl }

      return `${this.confirmUrl}?${queryString}`
    },
    buildQueryString () {
      let queryObj = {}
      if(this.shouldTrackPrompt) { queryObj.dnr = '1' }
      if(this.redirectPath) { queryObj.redirect_path = this.redirectPath }

      return Object.keys(queryObj).map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(queryObj[key])
      }).join("&");
    }
  }
}
</script>
